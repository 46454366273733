import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";

import { campaignURL } from "../../services/apiService/configURL";
import { userServices } from "../../services/apiService/userServices";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function ConfirmOTPPage() {
  let { giftCode } = useParams();
  let navigate = useNavigate();
  let [isSubmit, setIsSubmit] = useState(false);
  let [state, setState] = useState(0);
  let [OTPParams, setOTPParams] = useState({});

  const handleChange = (otp) => {
    setState(otp);
    setOTPParams({ otp: otp, url: campaignURL });
  };
  const handleValidateOTP = (otp) => {
    setIsSubmit(true)
    userServices
      .postValidateOTP(OTPParams)
      .then((res) => {
        toast.success("Xác thực OTP thành công!");
        setTimeout(() => {
          setIsSubmit(false)
          navigate("/spinthewheel/" + giftCode);
        }, 2000);

      })
      .catch((err) => {
        setIsSubmit(false)
        toast.error("Xác thực OTP thất bại. Vui lòng thử lại!");
      });
  };
  const countDown = ({ minutes, seconds }) => {
    // Render a countdown
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  const handleResendOTP = () => {
    if (isSubmit === false) {
      setIsSubmit(true)
      userServices
        .postResendOTP()
        .then((res) => {
          toast.success("Gửi lại thành công!");
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsSubmit(false);
        });
    }
  };
  return (
    <div className="confirm-page container wrapper">
      <div className="confirm-title">
        <h1>Xác thực Mã OTP</h1>
        <p>
          Một mã xác thực gồm 6 chữ số <br />
          đã được gửi đến số điện thoại của bạn
        </p>
      </div>
      <div className="confirm-input">
        <h5>Nhập mã để tiếp tục</h5>
        <div className="user-otp">
          {" "}
          <OtpInput
            className="otp-element"
            value={state}
            onChange={handleChange}
            numInputs={6}
            isInputNum={true}
            separator={<span> </span>}
          />
          <div className="otp-reRequest">
            <p>Không nhận được mã</p>
            <span>
              <a
                onClick={() => {
                  handleResendOTP();
                }}
              >
                Gửi lại{" "}
              </a>
              <span>
                (<Countdown date={Date.now() + 300000} renderer={countDown} />)
              </span>
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              handleValidateOTP(state);
            }}
            disabled={isSubmit}
            className="confirm-button bold"
          >
            xác nhận
          </button>
        </div>
      </div>
    </div>
  );
}
