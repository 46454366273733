import React from "react";

export default function PrizeItem({ gift, voucher_img }) {
  return (
    <div className="gift">
      <div className="prize-img">
        <img src={voucher_img} alt="" />
      </div>
      <div className="get-prize-decription">
        <span className="gift-open-amount">{gift.amount}</span>{" "}
        <span className="gift-open-name">{gift.name}</span>
      </div>
    </div>
  );
}
