import React from "react";
import gift_bg from "../../assets/bg_gift.png";
import { NavLink, useParams } from "react-router-dom";

export default function AnnouncePrize({ winningGift, spinRemain }) {
  let { giftCode } = useParams();
  let { gift_name, gift_value, gift_image } = winningGift;
  const handleContinueSpin = () => {
    window.location.reload();
  };
  return (
    <div className="annouce-prize container">
      <div
        style={{
          backgroundImage: `url(${gift_value > 0 ? gift_bg : ""})`,
          width: "100%",
          objectFit: "contain",
          backgroundPosition: "center",
          marginTop: "20px",
        }}
      >
        {gift_value > 0 ? (
          <div className="announce-title">
            <h1>xin chúc mừng</h1>
            <p>Bạn đã trúng thưởng</p>
          </div>
        ) : (
          <div className="announce-title">
            <h1>{gift_name}</h1>
          </div>
        )}
        <div className="prize">
          <img
            src={gift_image}
            alt=""
            style={{ width: "80px", position: "relative", top: "-20px" }}
          />
          {gift_value > 0 ? (
            <h5>
              <span className="prize-name">{gift_name}</span>
            </h5>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="prize-decription">
        {gift_value > 0 && (
          <p>
            Đối với Giải thưởng là Thẻ quà tặng: Quí khách sẽ được gửi qua tin
            nhắn vào SĐT có kèm đường link voucher trong vòng 5 phút.
            <br /> Đối với Giải thưởng là vàng hoặc quà hiện vật: Bộ phận CSKH
            sẽ liên hệ với Khách hàng để thông báo và xin thông tin địa chỉ và
            tiến hành giao hàng tận nhà cho khách hàng. Quà tặng sẽ được trao
            trong vòng 45 ngày kể từ ngày kết thúc Chương trình. Vui lòng giữ
            tin nhắn SMS thông báo trúng giải để nhận thưởng.
          </p>
        )}
      </div>
      <div className="button-nav">
        <NavLink
          to={"/get-prize/" + giftCode}
          style={{ textDecoration: "none" }}
          className="get-prize"
        >
          Dừng quay
          <br />
          (nhận quà)
        </NavLink>
        <button
          onClick={() => {
            handleContinueSpin();
          }}
          className="continue"
        >
          <span className="bold">Quay tiếp</span>{" "}
          <span className="spin-remain">{spinRemain} lượt</span>
        </button>
      </div>
    </div>
  );
}
