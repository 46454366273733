import { axiosInstance, campaignURL } from "./configURL";

export const userServices = {
  getCampaignInfo: (giftCode) => {
    return axiosInstance.get("/ocr/campaign_info", {
      params: {
        url: giftCode,
      },
    });
  },
  postUserLogin: (data) => {
    return axiosInstance.post("/mvc/check_phone", { params: data });
  },
  postValidateOTP: (data) => {
    return axiosInstance.post("/mvc/validate_otp", { params: data });
  },
  postResendOTP: () => {
    return axiosInstance.post("/mvc/resend_otp", {
      params: {
        url: campaignURL,
      },
    });
  },
  getWheelReward: () => {
    return axiosInstance.post("/mvc/get_wheel_gift", {
      params: {
        url: campaignURL,
        phone: "0974241423",
      },
    });
  },
  postSpinGift: () => {
    return axiosInstance.post("/mvc/spin_gift", {
      params: {
        url: campaignURL,
      },
    });
  },
  getSeasionReward: (userPhone) => {
    return axiosInstance.post("/mvc/get_gift", {
      params: {
        url: campaignURL,
        phone: userPhone,
      },
    });
  },
  postReceiveGift: () => {
    return axiosInstance.post("/mvc/receive_gift", {
      params: {
        url: campaignURL,
      },
    });
  },
};
