
import React from "react";
import { useSelector } from "react-redux";
import phone from "../../assets/phone.png";
import email from "../../assets/email.png";
export default function FloatButton() {
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });
  return <div className="float-buttons">
    <a href={'tel:' + campaignInfo.hotline}>
        <div className="footer-phone">
        <img src={phone} alt="" />
        </div>
    </a>
    </div>;
}
