export let userAgent = window.navigator.userAgent,
  platform = window.navigator.platform,
  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
  iosPlatforms = ['iPhone', 'iPad', 'iPod'];
export function getOS() {
  let os = '';
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }
  return os;
}

export function isSupportDevice () {
  let access = false;
  //const host = window.location.origin;
  //let access_localhost = host.includes("localhost");
  //let os = getOS();
  //console.log(os);
  var width = (window.innerWidth > 0) ? window.innerWidth : document.documentElement.clientWidth;
  //console.log(width);
  //if ((os === "iOS" || os === "Android" || access_localhost) &&
  //(userAgent.indexOf("Safari") !== -1 || userAgent.indexOf("Chrome") !== -1) && width > 318) {
  //  access = true
  //}
  if (width > 318 && width < 900) {
    access = true
  }
  return access;
}
