import React, { useEffect } from "react";
import Header from "../../component/Header/Header";
import Footer from "../../component/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { userServices } from "../../services/apiService/userServices";
import { campaignInfoLocal } from "../../services/localService/localService";
import { setCampaignInfo } from "../../Redux/Action/userAction";
import default_bg from "../../assets/410.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HeaderWheel from "../../component/Header/HeaderWheel";

export default function FullLayoutWheel({ children }) {
  let dispatch = useDispatch();
  const { giftCode } = useParams();
  console.log(giftCode);

  const campaign = `https://hfs.mvc.com.vn/aktv24`;
  useEffect(() => {
    userServices
      .getCampaignInfo(campaign)
      .then((res) => {
        console.log(res);
        campaignInfoLocal.set(res);
        dispatch(setCampaignInfo(res));
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });
  return (
    <div
      style={{
        backgroundImage: `url(${default_bg})`,
        width: "100vw",
      }}
    >
      <HeaderWheel />
      {children}
      <Footer />
      <ToastContainer />
    </div>
  );
}
