import React from "react";
import { useSelector } from "react-redux";
import default_header_img from "../../assets/header.jpeg";
import arrow_left from "../../assets/left-direction.png";

export default function HeaderNav() {
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });

  const renderHeaderImages = () => {
    if (campaignInfo.logo_image_header) {
      return campaignInfo.logo_image_header.map((imageLink, index) => {
        return (
          <div key={index}>
            <img style={{ width: "100vw" }} src={imageLink} alt="" />
          </div>
        );
      });
    } else {
      return (
        <div>
          <img style={{ width: "100vw" }} src={default_header_img} alt="" />
        </div>
      );
    }
  };
  return (
    <div className="header-nav">
      {renderHeaderImages()}
      <div className="nav-left">
        <img src={arrow_left} alt="" />
      </div>
    </div>
  );
}
