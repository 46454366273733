import React, { useEffect, useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { userServices } from "../../services/apiService/userServices";
import {
  campaignURL,
  checkPhoneStatus,
  setAuthorization,
} from "../../services/apiService/configURL";
import { userDataLocal } from "../../services/localService/localService";
import { setUserData } from "../../Redux/Action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

export default function LoginPage() {
  let { giftCode } = useParams();
  console.log(giftCode);
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });
  let navigate = useNavigate();
  let [isDisableButton, setIsDisableButton] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let dispatch = useDispatch();
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  useEffect(() => {
    if (token) {
      return navigate("/spinthewheel/" + giftCode);
    }
  }, []);
  const openModal = (e) => {
    console.log("open modal");
    e.preventDefault();
    setShowModal(true);
    return false;
  };
  const closeModal = () => {
    console.log("open modal");
    setShowModal(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    setIsDisableButton(true);
    data.url = campaignURL;
    data.gift_code = giftCode;
    console.log(data);
    userServices
      .postUserLogin(data)
      .then((res) => {
        console.log(res);
        setAuthorization(res.token);
        userDataLocal.set(res);
        dispatch(setUserData(res));
        toast.success("Đăng nhập thành công!!");
        setTimeout(() => {
          if (
            res.session.status === checkPhoneStatus.notValidate ||
            !res.session.status
          ) {
            navigate("/confirm-otp/" + giftCode);
          } else {
            navigate("/spinthewheel/" + giftCode);
          }
        }, 1000);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        console.log("finnaly");
        setIsDisableButton(false);
      });
  };
  return (
    <div className="login-page container">
      <form name="phone" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-top">
          <h1 className="login-top_title">đăng nhập</h1>
          <p className="login-top_description">
            Nhập số điện thoại của bạn để nhận mã OTP
          </p>
        </div>
        <div className="user-input ">
          <input
            className="user-input-number"
            placeholder="Vui lòng nhập số điện thoại của bạn"
            {...register("phone", {
              required: "Không được để trống",
              pattern: {
                value: /\d+/,
                message: "Vui lòng nhập số",
              },
            })}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="phone"
          render={({ messages }) => {
            console.log("messages", messages);
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />
        <div className="policy-check ">
          <input
            id="check_tc"
            type="checkbox"
            {...register("is_agree_tc", {
              required: "Bạn phải đồng ý",
            })}
          />
          <label className="policy-decription" htmlFor="check_tc">
            Tôi đồng ý với các{" "}
            <a href="" onClick={openModal} className="bold">
              Điều khoản & Điều kiện
            </a>{" "}
            của chương trình.
          </label>
        </div>
        <ErrorMessage
          errors={errors}
          name="is_agree_tc"
          render={({ messages }) => {
            console.log("messages", messages);
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />

        <div className="login-button ">
          <button
            className={isDisableButton ? "disable bold" : "enable bold"}
            type="submit"
            disabled={isDisableButton}
          >
            tham gia ngay
          </button>

          <a
            className="rule bold"
            type="button"
            href={campaignInfo.tc_url}
            target="_blank"
          >
            thể lệ chương trình
          </a>
        </div>
      </form>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Điều khoản điều kiện"
      >
        <p>
          Khi đánh dấu vào ô này, tôi đồng ý cho P&G và bên cung cấp dịch vụ
          được P&G ủy quyền, công ty TNHH Truyền Thông Mẹ & Con được sử dụng,
          lưu trữ các thông tin cá nhân do tôi cung cấp, trong hoặc ngoài lãnh
          thổ Việt Nam, vào mục đích trao giải thưởng, báo cáo, quản lý chương
          trình khuyến mại. P&G và đơn vị dịch vụ được ủy quyền là công ty TNHH
          Truyền Thông Mẹ & Con sẽ lưu trữ các thông tin cá nhân trong thời hạn
          phù hợp với qui định của pháp luật. Để được tiếp cận, cập nhật, điều
          chỉnh thông tin cá nhân, tôi sẽ liên hệ P&G và đơn vị ủy quyền là công
          ty TNHH Truyền Thông Mẹ & Con theo địa chỉ: 48 Hoa Mai, Phường 2, Phú
          Nhuận, Tp.HCM và số điện thoại (028)35170278. Chi tiết chính sách của
          P&G về quản lý thông tin riêng tư tại
          <br />
          <a
            href="http://www.pg.com/privacy/english/privacy_notice.shtml"
            target="_blank"
          >
            http://www.pg.com/privacy/english/privacy_notice.shtml
          </a>
        </p>
        <button
          className="button-primary bold"
          type="button"
          onClick={closeModal}
        >
          Đóng
        </button>
      </Modal>
    </div>
  );
}
