import React from "react";
import { useSelector } from "react-redux";
import default_footer_img from "../../assets/footer.png";
import FloatButton from "../FloatButton/FloatButton";
export default function FullFooter() {
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });
  const renderFooterImages = () => {
    if (campaignInfo.logo_image_footer) {
      return campaignInfo.logo_image_footer.map((imageLink, index) => {
        return (
          <div key={index}>
            <img style={{ width: "100vw" }} src={imageLink} alt="" />
          </div>
        );
      });
    } else {
      return (
        <div>
          <img style={{ width: "100vw" }} src={default_footer_img} alt="" />
        </div>
      );
    }
  };
  return (
    <div className="footer-nav">
      {renderFooterImages()}
    </div>
  );
}
