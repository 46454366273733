import { userDataLocal } from "../../services/localService/localService";
import { SET_USER_DATA } from "../Constant/reduxConstants";

const initialState = {
  userData: userDataLocal.get(),
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return { ...state, userData: payload };
    default:
      return state;
  }
};
