import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { USER_DATA_LOCAL } from "../../services/localService/localService";

export default function ProtectedRoute({ children }) {
  let { giftCode } = useParams();
  let userData  = useSelector((state) => {
    return state.userReducer.userData;
  });

  if (!userData.token) {
    return <Navigate to={'/'+giftCode} replace />;
  } else {
    if (userData.wheel.gift_code !== giftCode) {
      localStorage.removeItem(USER_DATA_LOCAL);
      window.location.reload();
    }
    return <div>{children ? children : <Outlet />}</div>;
  }
}
