import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import FullLayout from "./HOC/FullLayout/FullLayout";
import LoginPage from "./pages/LoginPage/LoginPage";

import ConfirmOTPPage from "./pages/ConfirmOTP/ConfirmOTPPage";
import ProtectedRoute from "./HOC/ProtectedLayout/ProtectedRoute";
import SpinTheWheel from "./pages/SpinTheWheel/SpinTheWheel";

import EndOfSpinPage from "./pages/EndOfSpin/EndOfSpinPage";
import FullNavLayout from "./HOC/FullNavLayout/FullNavLayout";
import AnnouncePrize from "./pages/AnnouncePrice/AnnouncePrize";
import FullFooterHOC from "./HOC/FullFooter/FullFooterHOC";
import { useSelector } from "react-redux";
import { setAuthorization } from "./services/apiService/configURL";
import GetPrizePage from "./pages/getPrizePage/GetPrizePage";
import Modal from "react-modal";
import { isSupportDevice } from "./services/deviceModel";
import NoAccessDeviceBrowser from "./component/NoAccessDeviceBrowser/NoAccessDeviceBrowser";
import FloatButton from "./component/FloatButton/FloatButton";
import FullLayoutWheel from "./HOC/FullLayout/FullLayoutWheel";

Modal.setAppElement("#root");

function App() {
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });
  setAuthorization(token);
  let access = isSupportDevice();
  return (
    <div className="App">
      {access ? (
        <BrowserRouter basename="/aktv24/wheel">
          <Routes>
            <Route
              path="/:giftCode"
              element={
                <FullLayout>
                  <LoginPage />
                </FullLayout>
              }
            ></Route>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/confirm-otp/:giftCode"
                element={
                  <FullLayout>
                    <ConfirmOTPPage />
                  </FullLayout>
                }
              ></Route>
              <Route
                path="/spinthewheel/:giftCode"
                element={
                  <FullLayoutWheel>
                    <SpinTheWheel />
                  </FullLayoutWheel>
                }
              ></Route>
              <Route
                path="/announce-prize/:giftCode"
                element={
                  <FullNavLayout>
                    <AnnouncePrize />
                  </FullNavLayout>
                }
              ></Route>
              <Route
                path="/endofspin/:giftCode"
                element={
                  <FullFooterHOC>
                    <EndOfSpinPage />
                  </FullFooterHOC>
                }
              ></Route>
              <Route
                path="/get-prize/:giftCode"
                element={
                  <FullLayoutWheel>
                    <GetPrizePage />
                  </FullLayoutWheel>
                }
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      ) : (
        <NoAccessDeviceBrowser />
      )}
      <FloatButton></FloatButton>
    </div>
  );
}

export default App;
