import axios from "axios";
import { USER_DATA_LOCAL } from "../localService/localService";
const PHONE_STATUS_NOT_VALIDATE = "start";
const PHONE_STATUS_VALIDATED = "processing";

export const axiosInstance = axios.create({
  baseURL: "https://wsunfixgift.mvc.com.vn",
});
export let campaignURL = "https://hfs.mvc.com.vn/aktv24";
var url = window.location.href;

export const setAuthorization = (token) => {
  axiosInstance.defaults.headers.common["TOKEN"] = token;
};

export const checkPhoneStatus = {
  notValidate: PHONE_STATUS_NOT_VALIDATE,
  validated: PHONE_STATUS_VALIDATED,
};

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.result && response.data.result.meta) {
      console.log(response.data.result.meta);
      if (!response.data.result.meta.status) {
        if (response.data.result.meta.status_code === 401) {
          localStorage.removeItem(USER_DATA_LOCAL);
          window.location.reload();
        }
        return Promise.reject(response.data.result.meta.message);
      }
    }
    return response.data.result.data ? response.data.result.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
