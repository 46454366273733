import React from "react";
import end_img from "../../assets/endofspin.png";

export default function EndOfSpinPage() {
  return (
    <div className="end-spin container">
      <div className="end-title">
        <h1>
          bạn đã hết
          <br /> lượt quay
        </h1>
      </div>
      <div className="end-img">
        <img src={end_img} alt="" />
      </div>
      <div className="end-decription">
        <h5>Mọi chi tiết xin liên hệ Hotline để được hỗ trợ</h5>
      </div>
      <div className="end-nav">
        <button className="home-btn">Quay lại trang chủ</button>
      </div>
    </div>
  );
}
