import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { userServices } from "../../services/apiService/userServices";
import { campaignInfoLocal } from "../../services/localService/localService";
import { setCampaignInfo } from "../../Redux/Action/userAction";

import default_bg from "../../assets/410.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAuthorization } from "../../services/apiService/configURL";

import HeaderNav from "../../component/Header/HeaderNav";
import FooterPhone from "../../component/Footer/FooterPhone";

export default function FullNavLayout({ children }) {
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });
  let dispatch = useDispatch();
  useEffect(() => {
    setAuthorization(token);
    userServices
      .getCampaignInfo()
      .then((res) => {
        campaignInfoLocal.set(res);
        dispatch(setCampaignInfo(res));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, []);
  let campaignInfo = useSelector((state) => {
    return state.campaignInfoReducer.campaignInfo;
  });
  return (
    <div
      style={{
        backgroundImage: `url(${
          campaignInfo?.background_img
            ? campaignInfo.background_img
            : default_bg
        })`,
        width: "100vw",
      }}
    >
      <HeaderNav />
      {children}
      <FooterPhone />
      <ToastContainer />
    </div>
  );
}
