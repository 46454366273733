import { campaignInfoLocal } from "../../services/localService/localService";
import { SET_CAMPAIGN_INFO } from "../Constant/reduxConstants";

const initialState = {
  campaignInfo: campaignInfoLocal.get(),
};

export const campaignInfoReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_CAMPAIGN_INFO:
      return { ...state, campaignInfo: payload };
    default:
      return state;
  }
};
