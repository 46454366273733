import React from "react";

export default function NoAccessDeviceBrowser() {
  return <div>
     <div className="noaccess-content">
        <p>
            Vui lòng sử dụng thiết bị di động được hỗ trợ!
        </p>
               
    </div>
  </div>;
}
